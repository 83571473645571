import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-archive
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTJ2LTEiIC8+CiAgPHBhdGggZD0iTTEwIDE4di0yIiAvPgogIDxwYXRoIGQ9Ik0xMCA3VjYiIC8+CiAgPHBhdGggZD0iTTE0IDJ2NGEyIDIgMCAwIDAgMiAyaDQiIC8+CiAgPHBhdGggZD0iTTE1LjUgMjJIMThhMiAyIDAgMCAwIDItMlY3bC01LTVINmEyIDIgMCAwIDAtMiAydjE2YTIgMiAwIDAgMCAuMjc0IDEuMDEiIC8+CiAgPGNpcmNsZSBjeD0iMTAiIGN5PSIyMCIgcj0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-archive
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileArchive: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 12v-1' }],
    ['path', { d: 'M10 18v-2' }],
    ['path', { d: 'M10 7V6' }],
    ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
    ['path', { d: 'M15.5 22H18a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v16a2 2 0 0 0 .274 1.01' }],
    ['circle', { cx: '10', cy: '20', r: '2' }],
  ],
];

export default FileArchive;
