import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mic-vocal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTEgNy42MDEtNS45OTQgOC4xOWExIDEgMCAwIDAgLjEgMS4yOThsLjgxNy44MThhMSAxIDAgMCAwIDEuMzE0LjA4N0wxNS4wOSAxMiIgLz4KICA8cGF0aCBkPSJNMTYuNSAyMS4xNzRDMTUuNSAyMC41IDE0LjM3MiAyMCAxMyAyMGMtMi4wNTggMC0zLjkyOCAyLjM1Ni02IDItMi4wNzItLjM1Ni0yLjc3NS0zLjM2OS0xLjUtNC41IiAvPgogIDxjaXJjbGUgY3g9IjE2IiBjeT0iNyIgcj0iNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mic-vocal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MicVocal: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'm11 7.601-5.994 8.19a1 1 0 0 0 .1 1.298l.817.818a1 1 0 0 0 1.314.087L15.09 12' },
    ],
    [
      'path',
      {
        d: 'M16.5 21.174C15.5 20.5 14.372 20 13 20c-2.058 0-3.928 2.356-6 2-2.072-.356-2.775-3.369-1.5-4.5',
      },
    ],
    ['circle', { cx: '16', cy: '7', r: '5' }],
  ],
];

export default MicVocal;
