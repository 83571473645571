import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lasso-select
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAyMmE1IDUgMCAwIDEtMi00IiAvPgogIDxwYXRoIGQ9Ik03IDE2LjkzYy45Ni40MyAxLjk2Ljc0IDIuOTkuOTEiIC8+CiAgPHBhdGggZD0iTTMuMzQgMTRBNi44IDYuOCAwIDAgMSAyIDEwYzAtNC40MiA0LjQ4LTggMTAtOHMxMCAzLjU4IDEwIDhhNy4xOSA3LjE5IDAgMCAxLS4zMyAyIiAvPgogIDxwYXRoIGQ9Ik01IDE4YTIgMiAwIDEgMCAwLTQgMiAyIDAgMCAwIDAgNHoiIC8+CiAgPHBhdGggZD0iTTE0LjMzIDIyaC0uMDlhLjM1LjM1IDAgMCAxLS4yNC0uMzJ2LTEwYS4zNC4zNCAwIDAgMSAuMzMtLjM0Yy4wOCAwIC4xNS4wMy4yMS4wOGw3LjM0IDZhLjMzLjMzIDAgMCAxLS4yMS41OWgtNC40OWwtMi41NyAzLjg1YS4zNS4zNSAwIDAgMS0uMjguMTR6IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/lasso-select
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LassoSelect: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 22a5 5 0 0 1-2-4' }],
    ['path', { d: 'M7 16.93c.96.43 1.96.74 2.99.91' }],
    [
      'path',
      { d: 'M3.34 14A6.8 6.8 0 0 1 2 10c0-4.42 4.48-8 10-8s10 3.58 10 8a7.19 7.19 0 0 1-.33 2' },
    ],
    ['path', { d: 'M5 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' }],
    [
      'path',
      {
        d: 'M14.33 22h-.09a.35.35 0 0 1-.24-.32v-10a.34.34 0 0 1 .33-.34c.08 0 .15.03.21.08l7.34 6a.33.33 0 0 1-.21.59h-4.49l-2.57 3.85a.35.35 0 0 1-.28.14z',
      },
    ],
  ],
];

export default LassoSelect;
