import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cigarette-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTJIM2ExIDEgMCAwIDAtMSAxdjJhMSAxIDAgMCAwIDEgMWgxMyIgLz4KICA8cGF0aCBkPSJNMTggOGMwLTIuNS0yLTIuNS0yLTUiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KICA8cGF0aCBkPSJNMjEgMTJhMSAxIDAgMCAxIDEgMXYyYTEgMSAwIDAgMS0uNS44NjYiIC8+CiAgPHBhdGggZD0iTTIyIDhjMC0yLjUtMi0yLjUtMi01IiAvPgogIDxwYXRoIGQ9Ik03IDEydjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/cigarette-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CigaretteOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 12H3a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h13' }],
    ['path', { d: 'M18 8c0-2.5-2-2.5-2-5' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M21 12a1 1 0 0 1 1 1v2a1 1 0 0 1-.5.866' }],
    ['path', { d: 'M22 8c0-2.5-2-2.5-2-5' }],
    ['path', { d: 'M7 12v4' }],
  ],
];

export default CigaretteOff;
