import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cake
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgMjF2LThhMiAyIDAgMCAwLTItMkg2YTIgMiAwIDAgMC0yIDJ2OCIgLz4KICA8cGF0aCBkPSJNNCAxNnMuNS0xIDItMSAyLjUgMiA0IDIgMi41LTIgNC0yIDIuNSAyIDQgMiAyLTEgMi0xIiAvPgogIDxwYXRoIGQ9Ik0yIDIxaDIwIiAvPgogIDxwYXRoIGQ9Ik03IDh2MyIgLz4KICA8cGF0aCBkPSJNMTIgOHYzIiAvPgogIDxwYXRoIGQ9Ik0xNyA4djMiIC8+CiAgPHBhdGggZD0iTTcgNGguMDEiIC8+CiAgPHBhdGggZD0iTTEyIDRoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNyA0aC4wMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cake
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cake: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 21v-8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8' }],
    ['path', { d: 'M4 16s.5-1 2-1 2.5 2 4 2 2.5-2 4-2 2.5 2 4 2 2-1 2-1' }],
    ['path', { d: 'M2 21h20' }],
    ['path', { d: 'M7 8v3' }],
    ['path', { d: 'M12 8v3' }],
    ['path', { d: 'M17 8v3' }],
    ['path', { d: 'M7 4h.01' }],
    ['path', { d: 'M12 4h.01' }],
    ['path', { d: 'M17 4h.01' }],
  ],
];

export default Cake;
