import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name chart-bar-big
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAzdjE2YTIgMiAwIDAgMCAyIDJoMTYiIC8+CiAgPHJlY3QgeD0iNyIgeT0iMTMiIHdpZHRoPSI5IiBoZWlnaHQ9IjQiIHJ4PSIxIiAvPgogIDxyZWN0IHg9IjciIHk9IjUiIHdpZHRoPSIxMiIgaGVpZ2h0PSI0IiByeD0iMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/chart-bar-big
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ChartBarBig: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3v16a2 2 0 0 0 2 2h16' }],
    ['rect', { x: '7', y: '13', width: '9', height: '4', rx: '1' }],
    ['rect', { x: '7', y: '5', width: '12', height: '4', rx: '1' }],
  ],
];

export default ChartBarBig;
