import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cloud-moon-rain
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuMTg4IDguNUE2IDYgMCAwIDEgMTYgNGExIDEgMCAwIDAgNiA2IDYgNiAwIDAgMS0zIDUuMTk3IiAvPgogIDxwYXRoIGQ9Ik0xMSAyMHYyIiAvPgogIDxwYXRoIGQ9Ik0zIDIwYTUgNSAwIDEgMSA4LjktNEgxM2EzIDMgMCAwIDEgMiA1LjI0IiAvPgogIDxwYXRoIGQ9Ik03IDE5djIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/cloud-moon-rain
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CloudMoonRain: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10.188 8.5A6 6 0 0 1 16 4a1 1 0 0 0 6 6 6 6 0 0 1-3 5.197' }],
    ['path', { d: 'M11 20v2' }],
    ['path', { d: 'M3 20a5 5 0 1 1 8.9-4H13a3 3 0 0 1 2 5.24' }],
    ['path', { d: 'M7 19v2' }],
  ],
];

export default CloudMoonRain;
