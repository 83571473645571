import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name vegan
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgOHE2IDAgNi02LTYgMC02IDYiIC8+CiAgPHBhdGggZD0iTTE3LjQxIDMuNTlhMTAgMTAgMCAxIDAgMyAzIiAvPgogIDxwYXRoIGQ9Ik0yIDJhMjYuNiAyNi42IDAgMCAxIDEwIDIwYy45LTYuODIgMS41LTkuNSA0LTE0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/vegan
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Vegan: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 8q6 0 6-6-6 0-6 6' }],
    ['path', { d: 'M17.41 3.59a10 10 0 1 0 3 3' }],
    ['path', { d: 'M2 2a26.6 26.6 0 0 1 10 20c.9-6.82 1.5-9.5 4-14' }],
  ],
];

export default Vegan;
