import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tractor
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTAgMTEgMTEgLjlhMSAxIDAgMCAxIC44IDEuMWwtLjY2NSA0LjE1OGExIDEgMCAwIDEtLjk4OC44NDJIMjAiIC8+CiAgPHBhdGggZD0iTTE2IDE4aC01IiAvPgogIDxwYXRoIGQ9Ik0xOCA1YTEgMSAwIDAgMC0xIDF2NS41NzMiIC8+CiAgPHBhdGggZD0iTTMgNGg4LjEyOWExIDEgMCAwIDEgLjk5Ljg2M0wxMyAxMS4yNDYiIC8+CiAgPHBhdGggZD0iTTQgMTFWNCIgLz4KICA8cGF0aCBkPSJNNyAxNWguMDEiIC8+CiAgPHBhdGggZD0iTTggMTAuMVY0IiAvPgogIDxjaXJjbGUgY3g9IjE4IiBjeT0iMTgiIHI9IjIiIC8+CiAgPGNpcmNsZSBjeD0iNyIgY3k9IjE1IiByPSI1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/tractor
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Tractor: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm10 11 11 .9a1 1 0 0 1 .8 1.1l-.665 4.158a1 1 0 0 1-.988.842H20' }],
    ['path', { d: 'M16 18h-5' }],
    ['path', { d: 'M18 5a1 1 0 0 0-1 1v5.573' }],
    ['path', { d: 'M3 4h8.129a1 1 0 0 1 .99.863L13 11.246' }],
    ['path', { d: 'M4 11V4' }],
    ['path', { d: 'M7 15h.01' }],
    ['path', { d: 'M8 10.1V4' }],
    ['circle', { cx: '18', cy: '18', r: '2' }],
    ['circle', { cx: '7', cy: '15', r: '5' }],
  ],
];

export default Tractor;
