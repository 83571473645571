import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name ethernet-port
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUgMjAgMy0zaDJhMiAyIDAgMCAwIDItMlY2YTIgMiAwIDAgMC0yLTJINGEyIDIgMCAwIDAtMiAydjlhMiAyIDAgMCAwIDIgMmgybDMgM3oiIC8+CiAgPHBhdGggZD0iTTYgOHYxIiAvPgogIDxwYXRoIGQ9Ik0xMCA4djEiIC8+CiAgPHBhdGggZD0iTTE0IDh2MSIgLz4KICA8cGF0aCBkPSJNMTggOHYxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/ethernet-port
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const EthernetPort: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'm15 20 3-3h2a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2l3 3z' },
    ],
    ['path', { d: 'M6 8v1' }],
    ['path', { d: 'M10 8v1' }],
    ['path', { d: 'M14 8v1' }],
    ['path', { d: 'M18 8v1' }],
  ],
];

export default EthernetPort;
