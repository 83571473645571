import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name test-tubes
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAydjE3LjVBMi41IDIuNSAwIDAgMSA2LjUgMjJBMi41IDIuNSAwIDAgMSA0IDE5LjVWMiIgLz4KICA8cGF0aCBkPSJNMjAgMnYxNy41YTIuNSAyLjUgMCAwIDEtMi41IDIuNWEyLjUgMi41IDAgMCAxLTIuNS0yLjVWMiIgLz4KICA8cGF0aCBkPSJNMyAyaDciIC8+CiAgPHBhdGggZD0iTTE0IDJoNyIgLz4KICA8cGF0aCBkPSJNOSAxNkg0IiAvPgogIDxwYXRoIGQ9Ik0yMCAxNmgtNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/test-tubes
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TestTubes: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9 2v17.5A2.5 2.5 0 0 1 6.5 22A2.5 2.5 0 0 1 4 19.5V2' }],
    ['path', { d: 'M20 2v17.5a2.5 2.5 0 0 1-2.5 2.5a2.5 2.5 0 0 1-2.5-2.5V2' }],
    ['path', { d: 'M3 2h7' }],
    ['path', { d: 'M14 2h7' }],
    ['path', { d: 'M9 16H4' }],
    ['path', { d: 'M20 16h-5' }],
  ],
];

export default TestTubes;
