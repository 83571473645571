import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tickets-plane
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuNSAxN2gxLjIyN2EyIDIgMCAwIDAgMS4zNDUtLjUyTDE4IDEyIiAvPgogIDxwYXRoIGQ9Im0xMiAxMy41IDMuNzUuNSIgLz4KICA8cGF0aCBkPSJtNC41IDggMTAuNTgtNS4wNmExIDEgMCAwIDEgMS4zNDIuNDg4TDE4LjUgOCIgLz4KICA8cGF0aCBkPSJNNiAxMFY4IiAvPgogIDxwYXRoIGQ9Ik02IDE0djEiIC8+CiAgPHBhdGggZD0iTTYgMTl2MiIgLz4KICA8cmVjdCB4PSIyIiB5PSI4IiB3aWR0aD0iMjAiIGhlaWdodD0iMTMiIHJ4PSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/tickets-plane
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TicketsPlane: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10.5 17h1.227a2 2 0 0 0 1.345-.52L18 12' }],
    ['path', { d: 'm12 13.5 3.75.5' }],
    ['path', { d: 'm4.5 8 10.58-5.06a1 1 0 0 1 1.342.488L18.5 8' }],
    ['path', { d: 'M6 10V8' }],
    ['path', { d: 'M6 14v1' }],
    ['path', { d: 'M6 19v2' }],
    ['rect', { x: '2', y: '8', width: '20', height: '13', rx: '2' }],
  ],
];

export default TicketsPlane;
