import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-copy
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxNlY0YTIgMiAwIDAgMSAyLTJoMTEiIC8+CiAgPHBhdGggZD0iTTIyIDE4SDExYTIgMiAwIDEgMCAwIDRoMTAuNWEuNS41IDAgMCAwIC41LS41di0xNWEuNS41IDAgMCAwLS41LS41SDExYTIgMiAwIDAgMC0yIDJ2MTIiIC8+CiAgPHBhdGggZD0iTTUgMTRINGEyIDIgMCAxIDAgMCA0aDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/book-copy
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookCopy: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 16V4a2 2 0 0 1 2-2h11' }],
    [
      'path',
      {
        d: 'M22 18H11a2 2 0 1 0 0 4h10.5a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5H11a2 2 0 0 0-2 2v12',
      },
    ],
    ['path', { d: 'M5 14H4a2 2 0 1 0 0 4h1' }],
  ],
];

export default BookCopy;
