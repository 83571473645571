import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name map-pin-check
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkuNDMgMTIuOTM1Yy4zNTctLjk2Ny41Ny0xLjk1NS41Ny0yLjkzNWE4IDggMCAwIDAtMTYgMGMwIDQuOTkzIDUuNTM5IDEwLjE5MyA3LjM5OSAxMS43OTlhMSAxIDAgMCAwIDEuMjAyIDAgMzIuMTk3IDMyLjE5NyAwIDAgMCAuODEzLS43MjgiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMCIgcj0iMyIgLz4KICA8cGF0aCBkPSJtMTYgMTggMiAyIDQtNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/map-pin-check
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MapPinCheck: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M19.43 12.935c.357-.967.57-1.955.57-2.935a8 8 0 0 0-16 0c0 4.993 5.539 10.193 7.399 11.799a1 1 0 0 0 1.202 0 32.197 32.197 0 0 0 .813-.728',
      },
    ],
    ['circle', { cx: '12', cy: '10', r: '3' }],
    ['path', { d: 'm16 18 2 2 4-4' }],
  ],
];

export default MapPinCheck;
