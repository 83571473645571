import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name origami
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTJWNGExIDEgMCAwIDEgMS0xaDYuMjk3YTEgMSAwIDAgMSAuNjUxIDEuNzU5bC00LjY5NiA0LjAyNSIgLz4KICA8cGF0aCBkPSJtMTIgMjEtNy40MTQtNy40MTRBMiAyIDAgMCAxIDQgMTIuMTcyVjYuNDE1YTEuMDAyIDEuMDAyIDAgMCAxIDEuNzA3LS43MDdMMjAgMjAuMDA5IiAvPgogIDxwYXRoIGQ9Im0xMi4yMTQgMy4zODEgOC40MTQgMTQuOTY2YTEgMSAwIDAgMS0uMTY3IDEuMTk5bC0xLjE2OCAxLjE2M2ExIDEgMCAwIDEtLjcwNi4yOTFINi4zNTFhMSAxIDAgMCAxLS42MjUtLjIxOUwzLjI1IDE4LjhhMSAxIDAgMCAxIC42MzEtMS43ODFsNC4xNjUuMDI3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/origami
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Origami: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 12V4a1 1 0 0 1 1-1h6.297a1 1 0 0 1 .651 1.759l-4.696 4.025' }],
    [
      'path',
      { d: 'm12 21-7.414-7.414A2 2 0 0 1 4 12.172V6.415a1.002 1.002 0 0 1 1.707-.707L20 20.009' },
    ],
    [
      'path',
      {
        d: 'm12.214 3.381 8.414 14.966a1 1 0 0 1-.167 1.199l-1.168 1.163a1 1 0 0 1-.706.291H6.351a1 1 0 0 1-.625-.219L3.25 18.8a1 1 0 0 1 .631-1.781l4.165.027',
      },
    ],
  ],
];

export default Origami;
