import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bot-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuNjcgOEgxOGEyIDIgMCAwIDEgMiAydjQuMzMiIC8+CiAgPHBhdGggZD0iTTIgMTRoMiIgLz4KICA8cGF0aCBkPSJNMjAgMTRoMiIgLz4KICA8cGF0aCBkPSJNMjIgMjIgMiAyIiAvPgogIDxwYXRoIGQ9Ik04IDhINmEyIDIgMCAwIDAtMiAydjhhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMS40MTQtLjU4NiIgLz4KICA8cGF0aCBkPSJNOSAxM3YyIiAvPgogIDxwYXRoIGQ9Ik05LjY3IDRIMTJ2Mi4zMyIgLz4KPC9zdmc+) - https://lucide.dev/icons/bot-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BotOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M13.67 8H18a2 2 0 0 1 2 2v4.33' }],
    ['path', { d: 'M2 14h2' }],
    ['path', { d: 'M20 14h2' }],
    ['path', { d: 'M22 22 2 2' }],
    ['path', { d: 'M8 8H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 1.414-.586' }],
    ['path', { d: 'M9 13v2' }],
    ['path', { d: 'M9.67 4H12v2.33' }],
  ],
];

export default BotOff;
