import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name piggy-bank
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkgNWMtMS41IDAtMi44IDEuNC0zIDItMy41LTEuNS0xMS0uMy0xMSA1IDAgMS44IDAgMyAyIDQuNVYyMGg0di0yaDN2Mmg0di00YzEtLjUgMS43LTEgMi0yaDJ2LTRoLTJjMC0xLS41LTEuNS0xLTJWNXoiIC8+CiAgPHBhdGggZD0iTTIgOXYxYzAgMS4xLjkgMiAyIDJoMSIgLz4KICA8cGF0aCBkPSJNMTYgMTFoLjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/piggy-bank
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PiggyBank: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M19 5c-1.5 0-2.8 1.4-3 2-3.5-1.5-11-.3-11 5 0 1.8 0 3 2 4.5V20h4v-2h3v2h4v-4c1-.5 1.7-1 2-2h2v-4h-2c0-1-.5-1.5-1-2V5z',
      },
    ],
    ['path', { d: 'M2 9v1c0 1.1.9 2 2 2h1' }],
    ['path', { d: 'M16 11h.01' }],
  ],
];

export default PiggyBank;
