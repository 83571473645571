import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wind-arrow-down
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMnY4IiAvPgogIDxwYXRoIGQ9Ik0xMi44IDIxLjZBMiAyIDAgMSAwIDE0IDE4SDIiIC8+CiAgPHBhdGggZD0iTTE3LjUgMTBhMi41IDIuNSAwIDEgMSAyIDRIMiIgLz4KICA8cGF0aCBkPSJtNiA2IDQgNCA0LTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/wind-arrow-down
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WindArrowDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 2v8' }],
    ['path', { d: 'M12.8 21.6A2 2 0 1 0 14 18H2' }],
    ['path', { d: 'M17.5 10a2.5 2.5 0 1 1 2 4H2' }],
    ['path', { d: 'm6 6 4 4 4-4' }],
  ],
];

export default WindArrowDown;
