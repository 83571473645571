import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-heart
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgOC4yQTIuMjIgMi4yMiAwIDAgMCAxMy44IDZjLS44IDAtMS40LjMtMS44LjktLjQtLjYtMS0uOS0xLjgtLjlBMi4yMiAyLjIyIDAgMCAwIDggOC4yYzAgLjYuMyAxLjIuNyAxLjZBMjI2LjY1MiAyMjYuNjUyIDAgMCAwIDEyIDEzYTQwNCA0MDQgMCAwIDAgMy4zLTMuMSAyLjQxMyAyLjQxMyAwIDAgMCAuNy0xLjciIC8+CiAgPHBhdGggZD0iTTQgMTkuNXYtMTVBMi41IDIuNSAwIDAgMSA2LjUgMkgxOWExIDEgMCAwIDEgMSAxdjE4YTEgMSAwIDAgMS0xIDFINi41YTEgMSAwIDAgMSAwLTVIMjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/book-heart
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookHeart: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M16 8.2A2.22 2.22 0 0 0 13.8 6c-.8 0-1.4.3-1.8.9-.4-.6-1-.9-1.8-.9A2.22 2.22 0 0 0 8 8.2c0 .6.3 1.2.7 1.6A226.652 226.652 0 0 0 12 13a404 404 0 0 0 3.3-3.1 2.413 2.413 0 0 0 .7-1.7',
      },
    ],
    [
      'path',
      {
        d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20',
      },
    ],
  ],
];

export default BookHeart;
