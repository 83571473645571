import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name dam
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTEuMzFjMS4xNy41NiAxLjU0IDEuNjkgMy41IDEuNjkgMi41IDAgMi41LTIgNS0yIDEuMyAwIDEuOS41IDIuNSAxIiAvPgogIDxwYXRoIGQ9Ik0xMS43NSAxOGMuMzUuNSAxLjQ1IDEgMi43NSAxIDIuNSAwIDIuNS0yIDUtMiAxLjMgMCAxLjkuNSAyLjUgMSIgLz4KICA8cGF0aCBkPSJNMiAxMGg0IiAvPgogIDxwYXRoIGQ9Ik0yIDE0aDQiIC8+CiAgPHBhdGggZD0iTTIgMThoNCIgLz4KICA8cGF0aCBkPSJNMiA2aDQiIC8+CiAgPHBhdGggZD0iTTcgM2ExIDEgMCAwIDAtMSAxdjE2YTEgMSAwIDAgMCAxIDFoNGExIDEgMCAwIDAgMS0xTDEwIDRhMSAxIDAgMCAwLTEtMXoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/dam
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Dam: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 11.31c1.17.56 1.54 1.69 3.5 1.69 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1' }],
    ['path', { d: 'M11.75 18c.35.5 1.45 1 2.75 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1' }],
    ['path', { d: 'M2 10h4' }],
    ['path', { d: 'M2 14h4' }],
    ['path', { d: 'M2 18h4' }],
    ['path', { d: 'M2 6h4' }],
    ['path', { d: 'M7 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1L10 4a1 1 0 0 0-1-1z' }],
  ],
];

export default Dam;
