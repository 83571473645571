import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name codesandbox
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTZWOGEyIDIgMCAwIDAtMS0xLjczbC03LTRhMiAyIDAgMCAwLTIgMGwtNyA0QTIgMiAwIDAgMCAzIDh2OGEyIDIgMCAwIDAgMSAxLjczbDcgNGEyIDIgMCAwIDAgMiAwbDctNEEyIDIgMCAwIDAgMjEgMTZ6IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjcuNSA0LjIxIDEyIDYuODEgMTYuNSA0LjIxIiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjcuNSAxOS43OSA3LjUgMTQuNiAzIDEyIiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjIxIDEyIDE2LjUgMTQuNiAxNi41IDE5Ljc5IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjMuMjcgNi45NiAxMiAxMi4wMSAyMC43MyA2Ljk2IiAvPgogIDxsaW5lIHgxPSIxMiIgeDI9IjEyIiB5MT0iMjIuMDgiIHkyPSIxMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/codesandbox
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 * @deprecated Brand icons have been deprecated and are due to be removed, please refer to https://github.com/lucide-icons/lucide/issues/670. We recommend using https://simpleicons.org/?q=codesandbox instead. This icon will be removed in v1.0
 */
const Codesandbox: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z',
      },
    ],
    ['polyline', { points: '7.5 4.21 12 6.81 16.5 4.21' }],
    ['polyline', { points: '7.5 19.79 7.5 14.6 3 12' }],
    ['polyline', { points: '21 12 16.5 14.6 16.5 19.79' }],
    ['polyline', { points: '3.27 6.96 12 12.01 20.73 6.96' }],
    ['line', { x1: '12', x2: '12', y1: '22.08', y2: '12' }],
  ],
];

export default Codesandbox;
