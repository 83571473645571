import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name calendar-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUuMiAxNi45LS45LS40IiAvPgogIDxwYXRoIGQ9Im0xNS4yIDE5LjEtLjkuNCIgLz4KICA8cGF0aCBkPSJNMTYgMnY0IiAvPgogIDxwYXRoIGQ9Im0xNi45IDE1LjItLjQtLjkiIC8+CiAgPHBhdGggZD0ibTE2LjkgMjAuOC0uNC45IiAvPgogIDxwYXRoIGQ9Im0xOS41IDE0LjMtLjQuOSIgLz4KICA8cGF0aCBkPSJtMTkuNSAyMS43LS40LS45IiAvPgogIDxwYXRoIGQ9Ik0yMSAxMC41VjZhMiAyIDAgMCAwLTItMkg1YTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmg2IiAvPgogIDxwYXRoIGQ9Im0yMS43IDE2LjUtLjkuNCIgLz4KICA8cGF0aCBkPSJtMjEuNyAxOS41LS45LS40IiAvPgogIDxwYXRoIGQ9Ik0zIDEwaDE4IiAvPgogIDxwYXRoIGQ9Ik04IDJ2NCIgLz4KICA8Y2lyY2xlIGN4PSIxOCIgY3k9IjE4IiByPSIzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/calendar-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CalendarCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm15.2 16.9-.9-.4' }],
    ['path', { d: 'm15.2 19.1-.9.4' }],
    ['path', { d: 'M16 2v4' }],
    ['path', { d: 'm16.9 15.2-.4-.9' }],
    ['path', { d: 'm16.9 20.8-.4.9' }],
    ['path', { d: 'm19.5 14.3-.4.9' }],
    ['path', { d: 'm19.5 21.7-.4-.9' }],
    ['path', { d: 'M21 10.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6' }],
    ['path', { d: 'm21.7 16.5-.9.4' }],
    ['path', { d: 'm21.7 19.5-.9-.4' }],
    ['path', { d: 'M3 10h18' }],
    ['path', { d: 'M8 2v4' }],
    ['circle', { cx: '18', cy: '18', r: '3' }],
  ],
];

export default CalendarCog;
